import React from 'react';
import { Row, Col, Card, Carousel } from 'antd';
import './App.css';
import axios from 'axios'
import DPlayer from "react-dplayer";

function isMobile() {
  return window.innerWidth <= 768;
}

class AntdCard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      expand: false
    }
  }

  render() {
    const item = this.props.item
    const year = this.props.year
    const status = this.props.status || {}
    const healthy = status.status_code && status.status_code >= 200 && status.status_code < 300
    const sick = status.status_code && status.status_code >= 400
    const access = status.count
    let cover = undefined
    const renderPic = (pic, idx) => {
      const dplayerOptions = {
        video: { url: `${process.env.PUBLIC_URL}/img-compressed/${year}/${pic}` }
      }
      if (year === 2024 && pic === "music-0.mp4") {
        dplayerOptions["video"]["pic"] = `${process.env.PUBLIC_URL}/img-compressed/2024/music-0.png`
      } else if (year === 2024 && pic === "CompanionGLM-0.mp4") {
        dplayerOptions["video"]["pic"] = `${process.env.PUBLIC_URL}/img-compressed/2024/CompanionGLM-0.png`
      }
      if (pic.toLowerCase().endsWith('.mp4')) {
        return <div className="cover-img" key={idx}>
          {/* <video width="100%" controls>
            <source src={ `${process.env.PUBLIC_URL}/img-compressed/${year}/${pic}` } type="video/mp4" />
          </video> */}
          <DPlayer
            options={dplayerOptions}
          />
        </div>
      } else {
        return <div className="cover-img" key={idx}>
          <div className="cover-inner" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img-compressed/${year}/${pic})` }}></div>
        </div>
      }
    }
    // {
    //   return <div className="cover-img" key={idx}>
    //   <div className="cover-inner" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/img-compressed/${year}/${pic})`}}></div>
    // </div> }
    if (item.imgs.length === 1) cover = renderPic(item.imgs[0])
    else if (item.imgs.length > 1) cover = <Carousel autoplay={true} dotPosition="right">
      {item.imgs.map(renderPic)}
    </Carousel>
    return <Col ref={(e) => {this.el = e}} xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
      <div className="card-wrapper" style={{maxHeight: this.state.expand ? "none" : 480}}>
      <Card cover={cover && <div className="cover-container">{cover}</div>}>
        <Card.Meta title={<div className="card-meta-title-group">
          {/* <div className="meta-header">
            {healthy && <div className="working-badge">&#9679;运行中</div>}
            {sick && <div className="stopped-badge">&#9632;已停止</div>}
            {!healthy && !sick && <div className="unknown-badge">&#11042;未知</div>}
            {access && access >= 0 && <div className="card-meta-access-count">访问次数:{access}</div>}
          </div> */}
          <div className="card-meta-title" onClick={() => {if (item.href && item.href.length > 0) window.open(item.href, '_blank')}}>{item.title}</div>
          <div className="card-meta-author">{item.author}</div>
        </div>} description={
          <div className="card-meta-description" onClick={() => this.setState({expand: !this.state.expand})}>{item.description}</div>
        }
        />
        {!this.state.expand && <div className="card-wrapper-bottom"></div>}
      </Card>
      </div>
    </Col>
  }

}

export default class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      data: [],
      year: 2024,
      cnt: undefined,
      loading: true
    }
    this.dataCache = {};
  }

  componentDidMount() {
    this.loadData(this.state.year);
    this.loadAccessCount();
  }

  loadData = async (year) => {
    this.setState({ loading: true, year: year });
    try {
      if (!this.dataCache[year]) {
        let data;
        data = await import(`./data${year}.json`);
        this.dataCache[year] = data.default;
      }
      this.setState({ data: this.dataCache[year], currentYear: year, loading: false });
    } catch (error) {
      console.error('Error loading data:', error);
      this.setState({ loading: false });
    }
  }

  loadAccessCount = () => {
    // axios.get('https://lab.aminer.cn/isoa-2021-web/api/all').then(resp => resp.data).then(obj => {
    //   if (obj.status) this.setState({ data: obj.data })
    // }).catch(err => { console.error(err) })
    // axios.get('https://lab.aminer.cn/isoa-2021-web/api/access').then(resp => resp.data).then(obj => {
    //   if (obj.status) this.setState({ cnt: obj.count })
    // }).catch(err => { console.error(err) })
  }

  renderYearSection = () => {
    const years = [2020, 2021, 2023, 2024];
    return (
      <div className="year-section">
        {years.map(year => (
          <span
            key={year}
            className={`year-item ${this.state.year === year ? 'active' : ''}`}
            onClick={() => this.loadData(year)}
          >
            {year}
          </span>
        ))}
      </div>
    );
  }

  render2020Content() {
    const data = this.state.data
    const epdata = data.filter(d => d.category === 'epidemic')
    const academicdata = data.filter(d => d.category === 'academic')
    return (
      <>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="epidemic-title">COVID-19疫情可视化专题</div>
            <Row type="flex">
              {epdata.map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="academic-title">学术信息挖掘专题</div>
            <Row type="flex">
              {academicdata.map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        <div className="nav">
          <div className="nav-btn" style={{ background: "#cccccc" }}
            onClick={() => {
              const el = document.getElementById("intro-title")
              if (el) el.scrollIntoView()
            }}>简介</div>
          <div className="nav-btn" style={{ background: "#c9ffbf" }}
            onClick={() => {
              const el = document.getElementById("epidemic-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "疫情" : "COVID-19疫情可视化专题"}</div>
          <div className="nav-btn" style={{ background: "#c1ceff" }}
            onClick={() => {
              const el = document.getElementById("academic-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "学术" : "学术信息挖掘专题"}</div>
        </div>
      </>
    );
  }

  render2021Content() {
    const data = this.state.data
    return (
      <>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="creator-title">辅助创作专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'creator').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="knowledge-title">知识问答专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'knowledge').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="story-title">故事生成专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'story').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="poetry-title">诗歌生成专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'poetry').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="chat-title">聊天对话专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'chat').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>

        <div className="nav">
          <div className="nav-btn" style={{ background: "#cccccc" }}
            onClick={() => {
              const el = document.getElementById("intro-title")
              if (el) el.scrollIntoView()
            }}>简介</div>
          <div className="nav-btn" style={{ background: "#f4ffb8" }}
            onClick={() => {
              const el = document.getElementById("creator-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "创作" : "辅助创作专题"}</div>
          <div className="nav-btn" style={{ background: "#b5f5ec" }}
            onClick={() => {
              const el = document.getElementById("knowledge-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "问答" : "知识问答专题"}</div>
          <div className="nav-btn" style={{ background: "#bae7ff" }}
            onClick={() => {
              const el = document.getElementById("story-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "故事" : "故事生成专题"}</div>
          <div className="nav-btn" style={{ background: "#efdbff" }}
            onClick={() => {
              const el = document.getElementById("poetry-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "诗歌" : "诗歌生成专题"}</div>
          <div className="nav-btn" style={{ background: "#ffe7ba" }}
            onClick={() => {
              const el = document.getElementById("chat-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "聊天" : "聊天对话专题"}</div>
        </div>
      </>
    );
  }

  render2023Content() {
    const data = this.state.data
    return (
      <>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="creator-title">辅助创作专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'creator').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="story-title">故事生成专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'story').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="game-title">游戏娱乐专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'game').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="academic-title">学术辅助专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'academic').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="chat-title">聊天对话专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'chat').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>

        <div className="nav">
          <div className="nav-btn" style={{ background: "#cccccc" }}
            onClick={() => {
              const el = document.getElementById("intro-title")
              if (el) el.scrollIntoView()
            }}>简介</div>
          <div className="nav-btn" style={{ background: "#f4ffb8" }}
            onClick={() => {
              const el = document.getElementById("creator-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "创作" : "辅助创作专题"}</div>
          <div className="nav-btn" style={{ background: "#b5f5ec" }}
            onClick={() => {
              const el = document.getElementById("story-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "故事" : "故事生成专题"}</div>
          <div className="nav-btn" style={{ background: "#bae7ff" }}
            onClick={() => {
              const el = document.getElementById("game-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "游戏" : "游戏娱乐专题"}</div>
          <div className="nav-btn" style={{ background: "#efdbff" }}
            onClick={() => {
              const el = document.getElementById("academic-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "学术" : "学术辅助专题"}</div>
          <div className="nav-btn" style={{ background: "#ffe7ba" }}
            onClick={() => {
              const el = document.getElementById("chat-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "聊天" : "聊天对话专题"}</div>
        </div>
      </>
    );
  }

  render2024Content() {
    const data = this.state.data
    return (
      <>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="chat-title">对话互动专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'chat').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="creator-title">辅助创作专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'creator').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="game-title">角色扮演专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'game').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>
        {!isMobile() && <hr className="separator" />}
        <div className="column">
          <div className="content">
            <div className="bar" id="academic-title">学术辅助专题</div>
            <Row type="flex">
              {data.filter(d => d.category === 'academic').map((item, idx) => <AntdCard key={idx} idx={idx} item={item} year={this.state.year} status={data[item.author]} />)}
            </Row>
          </div>
        </div>

        <div className="nav">
          <div className="nav-btn" style={{ background: "#cccccc" }}
            onClick={() => {
              const el = document.getElementById("intro-title")
              if (el) el.scrollIntoView()
            }}>简介</div>
          <div className="nav-btn" style={{ background: "#ffe7ba" }}
            onClick={() => {
              const el = document.getElementById("chat-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "互动" : "对话互动专题"}</div>
          <div className="nav-btn" style={{ background: "#f4ffb8" }}
            onClick={() => {
              const el = document.getElementById("creator-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "创作" : "辅助创作专题"}</div>
          <div className="nav-btn" style={{ background: "#bae7ff" }}
            onClick={() => {
              const el = document.getElementById("game-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "游戏" : "角色扮演专题"}</div>
          <div className="nav-btn" style={{ background: "#efdbff" }}
            onClick={() => {
              const el = document.getElementById("academic-title")
              if (el) el.scrollIntoView()
            }}>{isMobile() ? "学术" : "学术辅助专题"}</div>
        </div>
      </>
    );
  }

  renderYearContent() {
    if (this.state.year === 2020) {
      return this.render2020Content();
    }

    else if (this.state.year === 2021) {
      return this.render2021Content();
    }

    else if (this.state.year === 2023) {
      return this.render2023Content();
    }

    else if (this.state.year === 2024) {
      return this.render2024Content();
    }
  }


  render() {
    return (
      <div className="App">
        <div className="header">以智能服务为中心的软件开发设计与实现</div>
        <div className="main-body">
          <div className="column">
            <div className="content">
              {this.renderYearSection()}
              <div className="bar" id="intro-title">简介</div>
              {this.state.year === 2020 &&
                <div className="intro-section">
                  {"\t“以智能服务为中心的软件开发设计与实现”是清华大学计算机系本科生的课程之一，由"}<a href="http://keg.cs.tsinghua.edu.cn/jietang/" target="_blank" rel="noopener noreferrer">唐杰老师</a>授课。
                  在2020年春季的课程中，同学们分别以“COVID-19疫情可视化”和“学术信息挖掘”为主题，编写了一系列高可用的智能服务。
                  以下为经同学们数周努力完成的各种有趣的功能。
                </div>
              }
              {this.state.year === 2021 &&
                <div className="intro-section">
                  {"\t“以智能服务为中心的软件开发设计与实现”是清华大学计算机系本科生的课程之一，由"}<a href="http://keg.cs.tsinghua.edu.cn/jietang/" target="_blank" rel="noopener noreferrer">唐杰教授</a>和<a href="http://keg.cs.tsinghua.edu.cn/persons/ljz/" target="_blank" rel="noopener noreferrer">李涓子教授</a>授课。
                  在2021年春季的课程中，同学们基于悟道超大规模预训练语言模型编写了一系列智能生成服务。
                  以下为经同学们数周努力完成的各种有趣的成果，包括故事续写、视频自动制作、看图吟诗等有趣的应用。
                </div>
              }
              {this.state.year === 2023 &&
                <div className="intro-section">
                  {"\t“以智能服务为中心的软件开发设计与实现”是清华大学计算机系本科生的课程之一，由"}<a href="http://keg.cs.tsinghua.edu.cn/jietang/" target="_blank" rel="noopener noreferrer">唐杰教授</a>和<a href="http://keg.cs.tsinghua.edu.cn/persons/ljz/" target="_blank" rel="noopener noreferrer">李涓子教授</a>授课。
                  在2023年春季的课程中，同学们基于 ChatGPT、<a href="http://chatglm.cn">ChatGLM</a>、Stable Diffusion 等大模型编写了一系列智能服务。
                  以下为经同学们数周努力完成的各种有趣的成果，包括狼人杀、RPG游戏、文字冒险、文献综述、学习辅助等有趣的应用。
                </div>
              }
              {this.state.year === 2024 &&
                <div className="intro-section">
                  {"\t“以智能服务为中心的软件开发设计与实现”是清华大学计算机系本科生的课程之一，由"}<a href="http://keg.cs.tsinghua.edu.cn/jietang/" target="_blank" rel="noopener noreferrer">唐杰教授</a>和<a href="http://keg.cs.tsinghua.edu.cn/persons/ljz/" target="_blank" rel="noopener noreferrer">李涓子教授</a>授课。
                  在2024年春季的课程中，同学们基于 ChatGPT、<a href="http://chatglm.cn">ChatGLM</a>、Stable Diffusion 等大模型编写了一系列智能体服务。
                  以下为经同学们数周努力完成的各种有趣的成果，包括智能桌面助手、角色扮演游戏、研究辅助系统、旅行规划、求职助手等有趣的应用。
                </div>
              }
            </div>
          </div>

          {this.renderYearContent()}
          
          <div className="footnote">
            {this.state.cnt && <p>历史访问次数： {this.state.cnt}</p>}
            <p>iSOA 2024 Spring <a href="https://keg.cs.tsinghua.edu.cn">@THU</a></p>
          </div>
        </div>
      </div>
    )
  }
};
